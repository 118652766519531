import React from "react"
import ProfileRouteAnonymous from "../../../../components/auth/ProfileRoute/Anonymous"
import ChangeStartupName from "../../../../components/MyAccount/ChangeStartupName"

const ChangeStartupNameAnonymousPage = props => (
  <ProfileRouteAnonymous>
    <ChangeStartupName />
  </ProfileRouteAnonymous>
)

export default ChangeStartupNameAnonymousPage
